import Auth from '../components/auth/Auth';

import { AuthRedirect } from '../hooks/useUser';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    };
};

export default function LoginPage() {
    AuthRedirect();

    return <Auth signUp={false} />;
}
